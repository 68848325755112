
import { defineComponent, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { Product, PricingModel, ProductCategory, Artist } from "../main";
import { CachePolicy } from "vuebase/src/storage";

export default defineComponent({
    name: "EditProduct",
    setup() {
        const PricingModels = Object.values(PricingModel);
        const ProductCategories = Object.values(ProductCategory);
        const database = inject(Database.InjectionKey);
        const fixedAspectRatio = ref(true);
        const blankProduct = {
            category: ProductCategory.CustomPrint,
            name: "",
            caption: "",
            description: "",
            images: [],
            pricing: [],
            packaging: {
                size: {
                    width: 0,
                    height: 0,
                    length: 0,
                    relative: true,
                },
                mass: {
                    weight: 0,
                    measurement: "Flat",
                    relative: false,
                },
                rigid: true,
            },
            selections: [],
        };
        let product = database?.collection<Product>("products").document(useRoute().params.id as string);
        let artists = database?.collection<Artist>("artists").documents();
        const router = useRouter();

        if (product && !product.data) {
            product.data = blankProduct;
        }

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "products/" + product?.id,
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function newSelection(): void {
            const newSelection = {
                name: "Selection",
                options: [],
            };

            if (product && product.data && !product.data.selections) {
                product.data.selections = [];
            }

            product?.data?.selections.push(newSelection);
        }

        function newOption(selection: { options: unknown[] }): void {
            const newOption = {
                name: "Option",
                description: "",
                images: [],
                pricing: [],
                packaging: {
                    size: {
                        width: 0,
                        height: 0,
                        length: 0,
                        relative: true,
                    },
                    mass: {
                        weight: 0,
                        measurement: "Flat",
                        relative: false,
                    },
                    rigid: true,
                },
            };

            selection.options.push(newOption);
        }

        function addProductPricing(): void {
            product?.data?.pricing.push({
                price: 0,
                model: PricingModel.FlatFee,
            });
        }

        function removeProductPricing(index: number): void {
            product?.data?.pricing.splice(index, 1);
        }

        function addOptionPricing(option: { pricing: unknown[] }): void {
            option.pricing.push({
                price: 0,
                model: PricingModel.FlatFee,
            });
        }

        function removeOptionPricing(option: { pricing: unknown[] }, index: number): void {
            option.pricing.splice(index, 1);
        }

        async function save(): Promise<void> {
            await product?.save();
        }

        function remove(): void {
            if (confirm("Are you sure you want to delete this product?")) {
                product?.delete();
                router.back();
            }
        }

        function reorderItem(array: unknown[], from: number, to: number): void {
            const element = array[from];
            array.splice(from, 1);
            array.splice(to, 0, element);
        }

        function deleteItem(array: unknown[], from: number): void {
            if (confirm("Are you sure you want to delete this?")) {
                array.splice(from, 1);
            }
        }

        function addProductImage(image: ImageData): void {
            product?.data?.images.push(image);
        }

        function addOptionImage(option: { images: unknown[] }, image: ImageData): void {
            option.images.push(image);
        }

        return {
            product,
            artists,
            PricingModels,
            ProductCategories,
            fixedAspectRatio,
            newSelection,
            newOption,
            addProductPricing,
            removeProductPricing,
            addOptionPricing,
            removeOptionPricing,
            save,
            remove,
            reorderItem,
            deleteItem,
            uploadImage,
            addProductImage,
            addOptionImage,
        };
    },
});
