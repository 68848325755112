<template>
    <c-panel v-if="product && product.data">
        <c-grid class="spacing-xl-all widths-50-all widths-100-md">
            <c-grid-item>
                <h1>Details</h1>
                <c-grid class="widths-100-all spacing-xl-all">
                    <c-grid-item>
                        <c-grid class="widths-100-all">
                            <c-grid-item>
                                <c-textbox label="Id" v-model="product.id" />
                            </c-grid-item>
                            <c-grid-item>
                                <c-select
                                    label="Category"
                                    :options="ProductCategories"
                                    v-model="product.data.category"
                                />
                            </c-grid-item>
                            <c-grid-item v-if="product.data.category == 'Gallery Print'">
                                <c-select
                                    label="Artist"
                                    :options="artists"
                                    :display="(artist) => artist?.data?.name"
                                    :map="(artist) => artist.id"
                                    v-model="product.data.artist"
                                />
                            </c-grid-item>
                            <c-grid-item>
                                <c-textbox label="Name" v-model="product.data.name" />
                            </c-grid-item>
                            <c-grid-item>
                                <c-textarea label="Caption" lines="3" v-model="product.data.caption" />
                            </c-grid-item>
                            <c-grid-item>
                                <c-textarea label="Description" lines="8" v-model="product.data.description" />
                            </c-grid-item>
                        </c-grid>
                    </c-grid-item>

                    <c-grid-item>
                        <h1>Pricing</h1>
                        <c-grid v-for="(pricing, index) in product.data.pricing" :key="index">
                            <c-grid-item class="width-grow-all">
                                <c-textbox label="Price" v-model="pricing.price" type="dollars" prefix="$" />
                            </c-grid-item>
                            <c-grid-item class="width-grow-all">
                                <c-select label="Model" :options="PricingModels" v-model="pricing.model"></c-select>
                            </c-grid-item>
                            <c-grid-item class="width-shrink-all flex f-align-end">
                                <c-button class="mt-2" @click="removeProductPricing(index)">
                                    <template #icon>
                                        <i class="fas fa-trash"></i>
                                    </template>
                                </c-button>
                            </c-grid-item>
                        </c-grid>
                        <c-button class="mt-2" @click="addProductPricing()">
                            <template #icon><i class="fas fa-plus"></i> </template>
                        </c-button>
                    </c-grid-item>

                    <c-grid-item>
                        <h1>Packaging</h1>
                        <c-grid class="f-align-end">
                            <c-grid-item class="width-33-all">
                                <c-textbox
                                    label="Width"
                                    suffix="in"
                                    type="float"
                                    v-model="product.data.packaging.size.width"
                                />
                            </c-grid-item>
                            <c-grid-item class="width-33-all">
                                <c-textbox
                                    label="Height"
                                    suffix="in"
                                    type="float"
                                    v-model="product.data.packaging.size.height"
                                />
                            </c-grid-item>
                            <c-grid-item class="width-33-all">
                                <c-textbox
                                    label="Length"
                                    suffix="in"
                                    type="float"
                                    v-model="product.data.packaging.size.length"
                                />
                            </c-grid-item>

                            <c-grid-item class="width-33-all">
                                <c-textbox
                                    label="Weight"
                                    suffix="oz"
                                    type="float"
                                    v-model="product.data.packaging.mass.weight"
                                />
                            </c-grid-item>
                            <c-grid-item class="width-66-all">
                                <c-select
                                    label="Measurement"
                                    :options="['Flat', 'Area', 'Perimeter']"
                                    v-model="product.data.packaging.mass.measurement"
                                />
                            </c-grid-item>
                            <c-grid-item class="width-100-all">
                                <c-checkbox label="Rigid" v-model="product.data.packaging.rigid" />
                            </c-grid-item>
                        </c-grid>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>

            <c-grid-item>
                <h1>Images</h1>
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <c-checkbox label="Fixed Aspect Ratio" v-model="fixedAspectRatio" />
                    </c-grid-item>
                    <c-grid-item v-for="(image, index) in product.data.images" :key="index">
                        <c-card>
                            <template #image>
                                <c-image :image="image" />
                            </template>
                            <c-button-group class="floating-buttons merged" v-if="product != undefined">
                                <c-button
                                    @click="reorderItem(product.data.images, index, index - 1)"
                                    :disabled="index == 0"
                                >
                                    <template #icon>
                                        <i class="fas fa-arrow-up"></i>
                                    </template>
                                </c-button>
                                <c-button
                                    @click="reorderItem(product.data.images, index, index + 1)"
                                    :disabled="index == product.data.images.length - 1"
                                >
                                    <template #icon>
                                        <i class="fas fa-arrow-down"></i>
                                    </template>
                                </c-button>
                                <c-button class="red" @click="deleteItem(product.data.images, index)">
                                    <template #icon>
                                        <i class="fas fa-trash"></i>
                                    </template>
                                </c-button>
                            </c-button-group>
                        </c-card>
                    </c-grid-item>
                    <c-grid-item>
                        <c-image-picker
                            :aspectRatio="fixedAspectRatio ? 1.4 : undefined"
                            :upload="uploadImage"
                            :resetAfterApply="true"
                            @apply="addProductImage($event)"
                        ></c-image-picker>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>

            <c-grid-item class="width-100-all">
                <h1>Selections</h1>
                <c-accordion>
                    <c-accordion-item v-for="(selection, s) in product.data.selections" :key="s">
                        <template #label>
                            <c-grid>
                                <c-grid-item class="width-grow-all flex f-align-center">
                                    {{ selection.name }}
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button-group class="merged">
                                        <c-button
                                            @click="reorderItem(product.data.selections, s, s - 1)"
                                            :disabled="s == 0"
                                        >
                                            <template #icon>
                                                <i class="fas fa-arrow-up"></i>
                                            </template>
                                        </c-button>
                                        <c-button
                                            @click="reorderItem(product.data.selections, s, s + 1)"
                                            :disabled="s == product.data.selections.length - 1"
                                        >
                                            <template #icon>
                                                <i class="fas fa-arrow-down"></i>
                                            </template>
                                        </c-button>
                                        <c-button class="red" @click="deleteItem(product.data.selections, s)">
                                            <template #icon>
                                                <i class="fas fa-trash"></i>
                                            </template>
                                        </c-button>
                                    </c-button-group>
                                </c-grid-item>
                            </c-grid>
                        </template>
                        <c-grid class="widths-100-all">
                            <c-grid-item>
                                <c-textbox label="Name" v-model="selection.name" />
                            </c-grid-item>
                            <c-grid-item v-if="selection.options && selection.options.length > 0">
                                <c-accordion>
                                    <c-accordion-item v-for="(option, o) in selection.options" :key="o">
                                        <template #label>
                                            <c-grid>
                                                <c-grid-item class="width-grow-all flex f-align-center">
                                                    {{ option.name }}
                                                </c-grid-item>
                                                <c-grid-item>
                                                    <c-button-group class="merged">
                                                        <c-button
                                                            @click="reorderItem(selection.options, o, o - 1)"
                                                            :disabled="o == 0"
                                                        >
                                                            <template #icon>
                                                                <i class="fas fa-arrow-up"></i>
                                                            </template>
                                                        </c-button>
                                                        <c-button
                                                            @click="reorderItem(selection.options, o, o + 1)"
                                                            :disabled="o == selection.options.length - 1"
                                                        >
                                                            <template #icon>
                                                                <i class="fas fa-arrow-down"></i>
                                                            </template>
                                                        </c-button>
                                                        <c-button class="red" @click="deleteItem(selection.options, o)">
                                                            <template #icon>
                                                                <i class="fas fa-trash"></i>
                                                            </template>
                                                        </c-button>
                                                    </c-button-group>
                                                </c-grid-item>
                                            </c-grid>
                                        </template>
                                        <template #header>
                                            <c-button
                                                @click="reorderItem(selection.options, o, o - 1)"
                                                :disabled="o == 0"
                                            >
                                                <template #icon>
                                                    <i class="fas fa-arrow-up"></i>
                                                </template>
                                            </c-button>
                                            <c-button
                                                @click="reorderItem(selection.options, o, o + 1)"
                                                :disabled="o == selection.options.length - 1"
                                            >
                                                <template #icon>
                                                    <i class="fas fa-arrow-down"></i>
                                                </template>
                                            </c-button>
                                            <c-button @click="deleteItem(selection.options, o)">
                                                <template #icon>
                                                    <i class="fas fa-trash"></i>
                                                </template>
                                            </c-button>
                                        </template>
                                        <c-grid class="widths-50-all widths-100-md">
                                            <c-grid-item>
                                                <c-grid class="widths-100-all">
                                                    <c-grid-item>
                                                        <c-textbox label="Name" v-model="option.name" />
                                                    </c-grid-item>
                                                    <c-grid-item>
                                                        <c-textarea label="Description" v-model="option.description" />
                                                    </c-grid-item>
                                                    <c-grid-item>
                                                        <h1>Pricing</h1>
                                                        <c-grid v-for="(pricing, index) in option.pricing" :key="index">
                                                            <c-grid-item class="width-grow-all">
                                                                <c-textbox
                                                                    label="Price"
                                                                    v-model="pricing.price"
                                                                    type="dollars"
                                                                    prefix="$"
                                                                />
                                                            </c-grid-item>
                                                            <c-grid-item class="width-grow-all">
                                                                <c-select
                                                                    label="Model"
                                                                    :options="PricingModels"
                                                                    v-model="pricing.model"
                                                                ></c-select>
                                                            </c-grid-item>
                                                            <c-grid-item class="width-shrink-all flex f-align-end">
                                                                <c-button
                                                                    class="mt-2"
                                                                    @click="removeOptionPricing(option, index)"
                                                                >
                                                                    <i class="fas fa-trash"></i>
                                                                </c-button>
                                                            </c-grid-item>
                                                        </c-grid>
                                                        <c-button class="mt-2" @click="addOptionPricing(option)"
                                                            ><i class="fas fa-plus"></i
                                                        ></c-button>
                                                    </c-grid-item>
                                                    <c-grid-item>
                                                        <h1>Packaging</h1>
                                                        <c-grid class="f-align-end">
                                                            <c-grid-item class="width-33-all">
                                                                <c-textbox
                                                                    label="Width"
                                                                    suffix="in"
                                                                    type="float"
                                                                    v-model="option.packaging.size.width"
                                                                />
                                                            </c-grid-item>
                                                            <c-grid-item class="width-33-all">
                                                                <c-textbox
                                                                    label="Height"
                                                                    suffix="in"
                                                                    type="float"
                                                                    v-model="option.packaging.size.height"
                                                                />
                                                            </c-grid-item>
                                                            <c-grid-item class="width-33-all">
                                                                <c-textbox
                                                                    label="Length"
                                                                    suffix="in"
                                                                    type="float"
                                                                    v-model="option.packaging.size.length"
                                                                />
                                                            </c-grid-item>

                                                            <c-grid-item class="width-33-all">
                                                                <c-textbox
                                                                    label="Weight"
                                                                    suffix="oz"
                                                                    type="float"
                                                                    v-model="option.packaging.mass.weight"
                                                                />
                                                            </c-grid-item>
                                                            <c-grid-item class="width-66-all">
                                                                <c-select
                                                                    label="Measurement"
                                                                    :options="['Flat', 'Area', 'Perimeter']"
                                                                    v-model="option.packaging.mass.measurement"
                                                                />
                                                            </c-grid-item>
                                                            <c-grid-item class="width-100-all">
                                                                <c-checkbox
                                                                    label="Rigid"
                                                                    v-model="option.packaging.rigid"
                                                                />
                                                            </c-grid-item>
                                                        </c-grid>
                                                    </c-grid-item>
                                                </c-grid>
                                            </c-grid-item>
                                            <c-grid-item class="width-50-all width-100-md">
                                                <c-grid class="widths-100-all">
                                                    <c-grid-item v-for="(image, index) in option.images" :key="index">
                                                        <c-card>
                                                            <template #image>
                                                                <img :src="image.thumbnail" />
                                                            </template>
                                                            <c-button-group class="floating-buttons merged">
                                                                <c-button
                                                                    @click="
                                                                        reorderItem(option.images, index, index - 1)
                                                                    "
                                                                    :disabled="index == 0"
                                                                >
                                                                    <template #icon>
                                                                        <i class="fas fa-arrow-up"></i>
                                                                    </template>
                                                                </c-button>
                                                                <c-button
                                                                    @click="
                                                                        reorderItem(option.images, index, index + 1)
                                                                    "
                                                                    :disabled="index == option.images.length - 1"
                                                                >
                                                                    <template #icon>
                                                                        <i class="fas fa-arrow-down"></i>
                                                                    </template>
                                                                </c-button>
                                                                <c-button
                                                                    class="red"
                                                                    @click="deleteItem(option.images, index)"
                                                                >
                                                                    <template #icon>
                                                                        <i class="fas fa-trash"></i>
                                                                    </template>
                                                                </c-button>
                                                            </c-button-group>
                                                        </c-card>
                                                    </c-grid-item>
                                                    <c-grid-item>
                                                        <c-image-picker
                                                            :aspectRatio="1.5"
                                                            :upload="uploadImage"
                                                            :resetAfterApply="true"
                                                            @apply="addOptionImage(option, $event)"
                                                        ></c-image-picker>
                                                    </c-grid-item>
                                                </c-grid>
                                            </c-grid-item>
                                        </c-grid>
                                    </c-accordion-item>
                                </c-accordion>
                            </c-grid-item>
                            <c-grid-item>
                                <c-button @click="newOption(selection)">New Option</c-button>
                            </c-grid-item>
                        </c-grid>
                    </c-accordion-item>
                </c-accordion>
                <c-button class="mt-2" @click="newSelection()">New Selection</c-button>
            </c-grid-item>
            <c-grid-item class="width-100-all flex f-justify-end">
                <c-button-group>
                    <c-button class="primary" @click="save()">Save</c-button>
                    <c-button class="red" @click="remove()">
                        <template #icon>
                            <i class="fas fa-trash"></i>
                        </template>
                    </c-button>
                </c-button-group>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { Product, PricingModel, ProductCategory, Artist } from "../main";
import { CachePolicy } from "vuebase/src/storage";

export default defineComponent({
    name: "EditProduct",
    setup() {
        const PricingModels = Object.values(PricingModel);
        const ProductCategories = Object.values(ProductCategory);
        const database = inject(Database.InjectionKey);
        const fixedAspectRatio = ref(true);
        const blankProduct = {
            category: ProductCategory.CustomPrint,
            name: "",
            caption: "",
            description: "",
            images: [],
            pricing: [],
            packaging: {
                size: {
                    width: 0,
                    height: 0,
                    length: 0,
                    relative: true,
                },
                mass: {
                    weight: 0,
                    measurement: "Flat",
                    relative: false,
                },
                rigid: true,
            },
            selections: [],
        };
        let product = database?.collection<Product>("products").document(useRoute().params.id as string);
        let artists = database?.collection<Artist>("artists").documents();
        const router = useRouter();

        if (product && !product.data) {
            product.data = blankProduct;
        }

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "products/" + product?.id,
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function newSelection(): void {
            const newSelection = {
                name: "Selection",
                options: [],
            };

            if (product && product.data && !product.data.selections) {
                product.data.selections = [];
            }

            product?.data?.selections.push(newSelection);
        }

        function newOption(selection: { options: unknown[] }): void {
            const newOption = {
                name: "Option",
                description: "",
                images: [],
                pricing: [],
                packaging: {
                    size: {
                        width: 0,
                        height: 0,
                        length: 0,
                        relative: true,
                    },
                    mass: {
                        weight: 0,
                        measurement: "Flat",
                        relative: false,
                    },
                    rigid: true,
                },
            };

            selection.options.push(newOption);
        }

        function addProductPricing(): void {
            product?.data?.pricing.push({
                price: 0,
                model: PricingModel.FlatFee,
            });
        }

        function removeProductPricing(index: number): void {
            product?.data?.pricing.splice(index, 1);
        }

        function addOptionPricing(option: { pricing: unknown[] }): void {
            option.pricing.push({
                price: 0,
                model: PricingModel.FlatFee,
            });
        }

        function removeOptionPricing(option: { pricing: unknown[] }, index: number): void {
            option.pricing.splice(index, 1);
        }

        async function save(): Promise<void> {
            await product?.save();
        }

        function remove(): void {
            if (confirm("Are you sure you want to delete this product?")) {
                product?.delete();
                router.back();
            }
        }

        function reorderItem(array: unknown[], from: number, to: number): void {
            const element = array[from];
            array.splice(from, 1);
            array.splice(to, 0, element);
        }

        function deleteItem(array: unknown[], from: number): void {
            if (confirm("Are you sure you want to delete this?")) {
                array.splice(from, 1);
            }
        }

        function addProductImage(image: ImageData): void {
            product?.data?.images.push(image);
        }

        function addOptionImage(option: { images: unknown[] }, image: ImageData): void {
            option.images.push(image);
        }

        return {
            product,
            artists,
            PricingModels,
            ProductCategories,
            fixedAspectRatio,
            newSelection,
            newOption,
            addProductPricing,
            removeProductPricing,
            addOptionPricing,
            removeOptionPricing,
            save,
            remove,
            reorderItem,
            deleteItem,
            uploadImage,
            addProductImage,
            addOptionImage,
        };
    },
});
</script>

<style lang="scss" scoped>
.floating-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
